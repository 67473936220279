import {
  DCImg,
  DclinicsImg,
  HdImg,
  HospitalsImg,
  LogoImg,
  MSImg,
  MrImg,
  RESTImg,
  SASImg,
} from "../../assets/images";
import classes from "./home.module.scss";

const data = [
  {
    title: "Restaurant in Banglore",
    src: RESTImg,
    description: (
      <>
        Since food is also consider as medicine, we offer upto <span>30%</span>{" "}
        discount in maximum restaurant across Banglore (more than 400 new
        restaurant)
      </>
    ),
  },
  {
    title: "Salon and Spa",
    src: SASImg,
    description: (
      <>
        We have upto <span>30%</span> discount in and around <span>30%</span>{" "}
        off Salon and spa spread across Bangalore
      </>
    ),
  },

  {
    title: "Diagnostic Centers",
    src: DCImg,
    description: (
      <>
        Get up to <span>30%</span> discount in most diagnostic centers in and
        around Banglore
      </>
    ),
  },
  {
    title: "Dental Clinics",
    src: DclinicsImg,
    description: (
      <>
        Most dental clinics in Namma Bengaluru will give you up to{" "}
        <span>30%</span> discount
      </>
    ),
  },
  {
    title: "Medical Stores",
    src: MSImg,
    description: (
      <>
        Many Medical stores in and around Bengaluru will give you upto{" "}
        <span>30%</span>
        discount on your medicine purchase
      </>
    ),
  },
  {
    title: "Hospitals",
    src: HospitalsImg,
    description: (
      <>
        Discount upto <span>30%</span> at selected Hospitals Across Banglore
      </>
    ),
  },
  // {
  //   title: "2nd opinion on medical reports",
  //   src: MrImg,
  //   description: (
  //     <>
  //       Our website has expert doctors who can give you a second opinion on your
  //       medical reports at very minimal cost
  //     </>
  //   ),
  // },
];
const Home = () => {
  return (
    <div>
      <Header />
      <div className={classes.home}>
        <div>
          {data.map((i) => (
            <Block data={i} />
          ))}
        </div>
        <div className={classes.footer}>
          <div className={classes.info}>
            {/* <div>Hospitals Details</div> */}
            <div className={classes.description}>
              For More Details please call our customer care number{" "}
              <b>9353500960/8431613402</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={classes.header}>
      <div className={classes.logo}>{<img src={LogoImg} />}</div>
      <div className={classes.marquee}>
        <marquee>
          Join Us for exclusive Benefits with absolutely Free Membership offer
          !!!
        </marquee>
      </div>
      <div className={classes.launching}>launching soon ...</div>
    </div>
  );
};

const Block = ({ data: { src, title, description, description2 } }) => {
  return (
    <div className={classes.block}>
      <div>
        <img src={src} />
      </div>
      <div className={classes.info}>
        <div>{title}</div>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
};

export default Home;
